import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';


const CustomPagination = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const totalPages = props.totalPages;
    const activePage = parseInt(props.searchParams.get('page')) || 1; // 현재 활성화된 페이지
    const pages = []; // 페이징 버튼들을 저장할 배열
    // const [searchParams, setSearchParams] = useSearchParams();

    const onClickHandler = (pageNumber) => {
        console.log(props.searchParams.get('purchaseName'));
        const keys = Array.from(props.searchParams.keys()).filter(key => key !== 'page');
        console.log(keys);

        if (keys.length > 0) {
            navigate(`${props.navigateUrl}&page=${pageNumber}`)
        } else {
            navigate(`?page=${pageNumber}`)
        }
    };

    // 첫 페이지, 이전 페이지 버튼 생성
    pages.push(
        <Pagination.First
            key='first'
            disabled={activePage === 1}
            onClick={() => onClickHandler(1)}
        />,
        <Pagination.Prev
            key='prev'
            disabled={activePage === 1}
            onClick={() => onClickHandler(activePage - 1)}
        />
    );

    // 페이지 버튼 생성
    const maxPages = 10; // 최대 페이지 버튼 수
    let startPage = 1; // 시작 페이지 버튼
    let endPage = totalPages; // 끝 페이지 버튼
    if (totalPages > maxPages) { // 페이지 버튼이 최대 수를 초과하는 경우
        const halfMaxPages = Math.floor(maxPages / 2); // 최대 수의 절반
        startPage = activePage - halfMaxPages; // 시작 페이지 버튼 계산
        if (startPage < 1) { // 시작 페이지가 1보다 작은 경우
            startPage = 1;
            endPage = maxPages;
        } else {
            endPage = activePage + halfMaxPages; // 끝 페이지 버튼 계산
            if (endPage > totalPages) { // 끝 페이지가 총 페이지 수를 초과하는 경우
                endPage = totalPages;
                startPage = endPage - maxPages + 1;
            }
        }
    }
    for (let i = startPage; i <= endPage; i++) {
        pages.push(
            <Pagination.Item
                key={i}
                active={i === activePage}
                onClick={() => onClickHandler(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    // 다음 페이지, 마지막 페이지 버튼 생성
    pages.push(
        <Pagination.Next
            key='next'
            disabled={activePage === totalPages}
            onClick={() => onClickHandler(activePage + 1)}
        />,
        <Pagination.Last
            key='last'
            disabled={activePage === totalPages}
            onClick={() => onClickHandler(endPage)}
        />
    );

    return (
        <Pagination className='justify-content-center'>
            {pages}
        </Pagination>
    );
};

export default CustomPagination;