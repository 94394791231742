import axios from 'axios';

import CalendarComponent from '../components/CalendarComponent.js';
import Navbar from '../components/Navbar.js'
import Table from 'react-bootstrap/Table';
import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const DashBoard = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [orders, setOrders] = useState([]);
    const [viewTitle, setViewTitle] = useState(null); // View Title 상태
    const [monthlyTotal, setMonthlyTotal] = useState(0);

    useEffect(() => {
        if (viewTitle !== null) {
            axios.get(process.env.REACT_APP_HOST + `/api/v1/store/orders/monthly/${convertDateString(viewTitle)}`, {withCredentials: true})
                .then(response => {
                    const eventList = [];
                    response.data.result.data.forEach(data => {
                        const eventObj = {
                            title: data.dailyTotalPrice,
                            date: data.date
                        }
                        eventList.push(eventObj)
                    });
                    setEvents(eventList);
                    setMonthlyTotal(response.data.result.monthlyTotalPrice);
                }
                );
        }
    }, [viewTitle])

    useEffect(() => {
        if (date !== null) {
            axios.get(process.env.REACT_APP_HOST + `/api/v1/store/orders/daily/${date}`, {withCredentials: true})
                .then(response => {
                    setOrders(response.data.result.orders);
                });
        }
    }, [date])

    function convertDateString(dateString) {
        return `${dateString.split('/')[1]}-${dateString.split('/')[0]}`;
    }

    function handleOrderClick(order) {
        navigate(`/orders/list/${order.orderCode}`);
    }

    return (
        <div>
            <Navbar />
            <Container className='pb-5'>
                <div className='my-4' style={{ height: '3rem', lineHeight: '3rem', borderTop: '1px solid gray', borderBottom: '1px solid gray' }}>
                    당월 합계 : {monthlyTotal}
                </div>

                <CalendarComponent events={events} setDate={setDate} setViewTitle={setViewTitle} />

                {
                    date !== undefined && date !== null
                        ? <>
                            <h5 className='mt-3'>{date}</h5>
                            <Table striped bordered hover className='mt-2 text-center mb-5'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>주문 코드</th>
                                        <th>주문 금액</th>
                                        <th>주문 시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders && orders.map((order, idx) => {
                                            return (
                                                <tr key={idx} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }} >
                                                    <td>{idx + 1}</td>
                                                    <td>{order.orderCode}</td>
                                                    <td>{order.totalPrice}</td>
                                                    <td>{order.time}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                        : null
                }
            </Container>
        </div>
    );
}

export default DashBoard;