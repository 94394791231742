import { useState } from "react";
import { useZxing } from "react-zxing";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const NewScanner = (props) => {
  const [data, setData] = useState("");
  const [startScan, setStartScan] = useState(false);
  const [facingmode, setFacingmode] = useState("environment");

  const { ref } = useZxing({
    paused: !startScan,
    onResult(data) {
      console.log(data);
      setData(data.getText());
      setStartScan(false);

      //data 에서 productId, stockId 추출
      const productId = data.getText().split("/")[4];
      const stockId = data.getText().split("/")[5];
      props.setProductId(productId);
      props.setStockId(stockId);
    },
    onError(error) {
      console.log(error);
    },
    timeBetweenDecodingAttempts: 500,
    constraints: { video: { facingMode: facingmode }, audio: false }
  });

  const handleFacingMode = () => {
    setFacingmode(facingmode === "environment" ? "user" : "environment");
  };

  return (
    <Container>
      <Col className="d-flex justify-content-center align-items-center">
        {
          startScan &&
          <mark><h4>화면 중앙에 QR 코드를 위치시켜주세요</h4></mark>
        }
      </Col>
      <Col className="d-flex justify-content-center align-items-center mt-3">
        <Row>
          {startScan && (
            <video ref={ref} />
          )}
        </Row>
      </Col>
      <Col className="d-flex justify-content-center align-items-center pt-4">
        <Row>
          <Button size='lg' variant={startScan ? 'danger' : 'primary'} onClick={() => { setStartScan(!startScan) }}>
            {startScan ? "중지" : "스캔"}
          </Button>
        </Row>
      </Col>
      {
        startScan
          ? <Col className="d-flex justify-content-center align-items-center pt-3">
            <Row>
              <Button size='sm' variant={'secondary'} onClick={handleFacingMode}>
                카메라 전환
              </Button>
            </Row>
          </Col>
          : null
      }
    </Container>
  );
};

export default NewScanner;