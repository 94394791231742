import { useEffect } from "react";
import Table from 'react-bootstrap/Table'

const Bills = (props) => {

    useEffect(() => {
        props.setTotalPrice(props.cart.reduce((acc, item) => {
            return acc + item.count * item.price;
        }, 0))
    }, [props.cart])
    return (
        <>
            <Table striped="columns">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400' }}>#</th>
                        <th style={{ fontWeight: '400' }}>상품명</th>
                        <th style={{ fontWeight: '400' }}>색상</th>
                        <th style={{ fontWeight: '400' }}>사이즈</th>
                        <th style={{ fontWeight: '400' }}>금액</th>
                        <th style={{ fontWeight: '400' }}>수량</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.cart && props.cart.map((item, idx) => {
                            return (
                                <tr>
                                    <td>{item.stockId}</td>
                                    <td>{item.saleName}</td>
                                    <td>{item.color}</td>
                                    <td>{item.size}</td>
                                    <td>{item.price}</td>
                                    <td>{item.count}</td>
                                </tr>
                            );
                        })
                    }
                    <tr className='text-center'>
                        <td colSpan={4} style={{ fontWeight: '400', fontSize: '1.5rem' }}>총 금액</td>
                        <td colSpan={2} style={{ fontWeight: '400', fontSize: '1.5rem' }}>{props.totalPrice}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default Bills;