import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

const Login = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand>8784 Admin</Navbar.Brand>
                </Container>
            </Navbar>
            <div className='mx-auto'>
                <LoginForm />
            </div>
        </>
    );
}

export default Login;

const LoginForm = () => {
    const [email, setEamail] = useState('');
    const [password, setPassword] = useState('');
    const [cookies, setCookie] = useCookies(['accessToken']);

    function login() {
        axios.post(process.env.REACT_APP_HOST + '/api/v1/store/login', {
        // axios.post('http://3.38.94.101:8080/api/v1/store/login', {
            email: email,
            password, password,
            headers: {
                'Content-type': 'application/json',
            }
        })
            .then(response => {
                const jwt = response.data.result.accessToken;
                // 쿠키에 토큰 저장
                setCookie('accessToken', jwt, {
                    path: '/',
                    // domain: 'localhost',
                    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
                    // secure: true,
                    // sameSite: 'none',
                    // httpOnly: true,
                });
            });
    }

    return (
        <>

            <Container>
                <div className='text-center mt-4'>
                    <h1 className='mb-4'>Admin ログイン</h1>
                </div>
                <Form className='w-75 mx-auto'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>メール</Form.Label>
                        <Form.Control type="email" placeholder="メール" onChange={(e) => setEamail(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Control type="password" placeholder="パスワード" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <div className='text-center'>
                        <Button variant="primary" size='lg' onClick={() => login()}>
                            ログイン
                        </Button>

                    </div>
                </Form>
            </Container>
        </>
    );
}