import Scanner from "../components/Scanner";
import Navbar from '../components/Navbar.js'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import NewScanner from "../components/NewScanner";

const ScannerContainer = styled.div`
// height: 70vh;
// display: flex;
// justify-content: center;
// align-items: center;
`;

const GetStock = () => {
    const navigate = useNavigate();
    const [productId, setProductId] = useState(null);
    const [stockId, setStockId] = useState(null);

    useEffect(() => {
        if (productId !== null) {
            navigate(`/products/list/${productId}`)
        }
    }, [productId])

    return (
        <>
            <Navbar />

            <div className="text-center pt-5">
                <h1 style={{ fontWeight: 'bold' }}>재고조회</h1>
            </div>

            <ScannerContainer>
                <NewScanner setProductId={setProductId} setStockId={setStockId}/>
                {/* <Scanner setProductId={setProductId} setStockId={setStockId} /> */}
            </ScannerContainer>
        </>
    );
}

export default GetStock;