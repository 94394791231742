import { Routes, Route } from 'react-router-dom'
import { useSelector } from "react-redux";

import Categories from "../pages/Categories";
import DashBoard from "../pages/DashBoard";
import Login from '../pages/Login'
import CreateProduct from "../pages/CreateProduct";
import ProductList from "../pages/ProductList";
import ProductDetail from '../pages/ProductDetail';
import GetStock from '../pages/GetStock';
import Cart from '../pages/Cart';
import Receipt from '../pages/Receipt';
import Wholesaler from '../pages/Wholesaler';
import WholesalerDetail from '../pages/WholesalerDetail';
import OrderDetail from '../pages/OrderDetail';
import ProductDetailCustomer from '../pages/ProductDetilCustomer';
import { useCookies } from 'react-cookie';


const AppRouter = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login/>} />
            <Route path='/customer/receipts/:orderCode' element={<Receipt />} />
            <Route path='/customer/:productId/:stockId' element={<ProductDetailCustomer />} />
            <Route path='/*' element={<ProtectedRoutes/>} />
        </Routes>
    );
}

const ProtectedRoutes = () => {
    const [cookies] = useCookies(['accessToken']);

    const accessToken = cookies.accessToken;

    if (!accessToken) {
        return <Login/>;
    }

    return (
        <Routes>
            <Route path='/' element={<DashBoard />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/products/create' element={<CreateProduct />} />
            <Route path='/products/list' element={<ProductList />} />
            <Route path='/products/list/:id' element={<ProductDetail />} />
            <Route path='/products/scan' element={<GetStock />} />
            <Route path='/orders' element={<Cart />} />
            <Route path='/orders/list/:orderCode' element={<OrderDetail />} />
            <Route path='/wholesalers/list' element={<Wholesaler />} />
            <Route path='/wholesalers/list/:id' element={<WholesalerDetail />} />
        </Routes>
    );
};

export default AppRouter;