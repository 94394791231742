import { configureStore, createSlice } from '@reduxjs/toolkit'

//로그인 여부 확인 용도
let storeId = createSlice({
  name: 'storeId',
  initialState: null,
  reducers : {
    setStoreId(state, payload) {
      return payload;
    }
  }
});

export let { setStoreId } = storeId.actions

export default configureStore({
  reducer: { 
    storeId : storeId.reducer
  }
}) 