import React from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// 스타일드 컴포넌트 정의
const DropdownList = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    list-style: none;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    `;

const ListItem = styled.li`
    padding: 8px;
    cursor: pointer;

    &:hover {
    background-color: #f0f0f0;
    }
`;

export const ProductForm = () => {
    const navigate = useNavigate();
    const [옵션수, 옵션수변경] = useState(1);
    const [카테고리선택, 카테고리선택변경] = useState("0");
    const [categories, setCategories] = useState([]);
    const [wholesalerList, setWholesalerList] = useState([]);
    const [filteredWholesalerList, setFilteredWholesalerList] = useState([]);

    const [purchaseDate, setPurchaseDate] = useState();
    const [purchaseStore, setPurchaseStore] = useState();
    const [purchaseName, setPurchaseName] = useState();
    const [saleName, setSaleName] = useState();
    const [category, setCategory] = useState();
    const [gender, setGender] = useState();
    const [cost, setCost] = useState();
    const [price, setPrice] = useState();
    const [discountType, setDiscountType] = useState('NONE');
    const [discountAmount, setDiscountAmount] = useState(0);
    const [option, setOption] = useState([{ 'color': null, 'size': null, 'quantity': null }]);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const FormLabelEssential = styled.span`
        box-shadow: inset 0 -30px 0 #faf4a2;
        font-weight : bold;
    `;
    const FormLabel = styled.span`
        font-weight : bold;
    `;

    //최초 한 번 서버에서 해당 상점의 카테고리 정보를 조회하여 상태 변수에 저장합니다.
    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + '/api/v1/store/category', { withCredentials: true })
            .then((response) => {
                let categoryList = response.data.result.categoryList;
                setCategories(categoryList);
        });
        
        fetchAllWholesalerData();

        async function fetchAllWholesalerData() {
            let allData = [];
        
            try {
                let page = 1;
                let totalPages = 1; // 초기값 설정
        
                while (page <= totalPages) {
                const response = await axios.get(process.env.REACT_APP_HOST + '/api/v1/store/wholesalers/list', {
                    withCredentials: true,
                    params: {
                    page: page // 현재 페이지 번호
                    },
                });
        
                const result = response.data.result.content.map(object => object.name);
                allData = allData.concat(result);
        
                // totalPages 업데이트
                totalPages = response.data.result.totalPages;
        
                // 다음 페이지로 이동
                page++;
                }
        
                setWholesalerList(allData);
                setFilteredWholesalerList(allData);
            } catch (error) {
                console.error('데이터를 가져오는 중 에러 발생:', error);
            }
        }
    }, []);

    function submitHandler() {
        const url = process.env.REACT_APP_HOST + "/api/v1/store/products/add";
        const data = {
            'purchaseDate': purchaseDate,
            'purchaseName': purchaseName,
            'wholesalerName': purchaseStore,
            'saleName': saleName,
            'categoryId': category,
            'gender': gender,
            'tagPrice': price,
            'cost': cost,
            'discount': {
                'type': discountType,
                'amount': discountAmount
            },
            'options': option
        };
        const config = { 'Content-Type': 'application/json', withCredentials: true };

        axios.post(url, data, config)
            .then(response => {
                alert('상품 등록 완료!');
                navigate('/products/list');
            }).catch(err => {
                alert('[실패] 입력값이 올바르지 않습니다.')
                console.log(err);
            });
    }

    const handlePurchaseStoreChange = (e) => {
        const inputValue = e.target.value;
        const filteredWholesalers = filterLogic(inputValue);
        setFilteredWholesalerList(filteredWholesalers);
        setPurchaseStore(inputValue);
    };

    const handleWholesalerSelect = (selectedWholesaler) => {
        console.log(selectedWholesaler);
        setPurchaseStore(selectedWholesaler);
        setFilteredWholesalerList([]); // 거래처 목록 비우기
        setIsDropdownOpen(false);
    };

    const filterLogic = (inputValue) => {
        const filteredWholesalers = wholesalerList.filter((wholesaler) =>
            wholesaler.includes(inputValue)
        );
        return filteredWholesalers;
    };

    return (
        <Form>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseDate">
                    <Form.Label><FormLabel>사입 날짜</FormLabel></Form.Label>
                    <Form.Control type="date" onChange={e => setPurchaseDate(e.target.value)} />
                </Form.Group>

                <Form.Group as={Col} controlId="purchaseStore">
                    <Form.Label><FormLabel>거래처</FormLabel></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="거래처"
                        onChange={handlePurchaseStoreChange}
                        value={purchaseStore}
                        onFocus={() => setIsDropdownOpen(true)}
                        autoComplete='off'
                    />
                    {filteredWholesalerList.length > 0 && isDropdownOpen && (
                        <DropdownList>
                            {filteredWholesalerList.map((wholesaler, idx) => {
                                return (
                                    <ListItem key={idx} onClick={() => handleWholesalerSelect(wholesaler)}>
                                        {wholesaler}
                                    </ListItem>
                                );
                            })}
                        </DropdownList>
                    )}
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>사입명</FormLabel></Form.Label>
                    <Form.Control type="text" placeholder='사입명' onFocus={() => setIsDropdownOpen(false)} onChange={e => setPurchaseName(e.target.value)} />
                </Form.Group>

                <Form.Group as={Col} controlId="saleName">
                    <Form.Label><FormLabelEssential>판매명 *</FormLabelEssential></Form.Label>
                    <Form.Control type="text" placeholder="판매명" onFocus={() => setIsDropdownOpen(false)} onChange={e => setSaleName(e.target.value)} />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabelEssential>1차 카테고리 *</FormLabelEssential></Form.Label>
                    <Form.Select onChange={e => { 카테고리선택변경(e.target.value); }}>
                        <option value={0}>1차 카테고리</option>
                        {categories.map(category => {
                            return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabelEssential>2차 카테고리 *</FormLabelEssential></Form.Label>
                    <Form.Select onChange={e => setCategory(e.target.value)}>
                        <option value={0}>2차 카테고리</option>
                        {categories && 카테고리선택 === "0"
                            ? <option>2차 카테고리</option>
                            : categories.filter(category => category.id + "" === 카테고리선택)[0].children.map(child => {
                                return (
                                    <option key={child.id} value={child.id}>{child.name}</option>
                                );
                            })}
                    </Form.Select>
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label><FormLabel>성별</FormLabel></Form.Label>
                <Form.Select onChange={e => setGender(e.target.value)}>
                    <option>성별</option>
                    <option value="WOMAN">여자</option>
                    <option value="MAN">남자</option>
                    <option value="BOTH">남여공용</option>
                </Form.Select>
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>사입 가격</FormLabel></Form.Label>
                    <Form.Control type="number" placeholder='사입 가격' onChange={e => setCost(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId="saleName">
                    <Form.Label><FormLabelEssential>판매 가격 *</FormLabelEssential></Form.Label>
                    <Form.Control type="number" placeholder="판매 가격" onChange={e => setPrice(e.target.value)} />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>할인 정책</FormLabel></Form.Label>
                    <Form.Select onChange={e => setDiscountType(e.target.value)}>
                        <option selected value="NONE">NONE</option>
                        <option value="RATE">RATE</option>
                        <option value="FIX">FIX</option>
                    </Form.Select>
                </Form.Group>

                {(discountType === 'FIX')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label><FormLabelEssential>할인 금액 *</FormLabelEssential></Form.Label>
                        <Form.Control type="number" placeholder="할인 금액" onChange={e => setDiscountAmount(e.target.value)} />
                    </Form.Group>
                    : null}
                {(discountType === 'RATE')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label><FormLabelEssential>할인율 *</FormLabelEssential></Form.Label>
                        <Form.Control type="number" placeholder="할인율" onChange={e => setDiscountAmount(e.target.value)} />
                    </Form.Group>
                    : null}
            </Row>

            <div style={{ display: "flex", paddingBottom: "1rem" }}>
                <span><FormLabelEssential>상품/재고 옵션 *</FormLabelEssential></span>
                <Button variant="outline-success" size='sm' style={{ marginLeft: "auto" }} onClick={() => {
                    옵션수변경(옵션수 + 1);
                    let copy = [...option];
                    let newOption = { 'color': null, 'size': null, 'quantity': null };
                    copy.push(newOption);
                    setOption(copy);
                }}>
                    옵션 추가
                </Button>
                <Button onClick={() => {
                    if (옵션수 > 1) 옵션수변경(옵션수 - 1);
                    let copy = [...option];
                    copy.pop();
                    setOption(copy);
                }} variant="outline-danger" size='sm' style={{ marginLeft: "1rem" }}>
                    옵션 삭제
                </Button>
            </div>
            {[...Array(옵션수)].map((data, i) => {
                return (
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label><FormLabelEssential>색상</FormLabelEssential></Form.Label>
                            <Form.Control type="text" placeholder="색상" onChange={e => {
                                let copy = [...option];
                                copy[i].color = e.target.value;
                                setOption(copy);
                            }} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label><FormLabelEssential>사이즈</FormLabelEssential></Form.Label>
                            <Form.Control type="text" placeholder="사이즈" onChange={e => {
                                let copy = [...option];
                                copy[i].size = e.target.value;
                                setOption(copy);
                            }} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label><FormLabelEssential>수량</FormLabelEssential></Form.Label>
                            <Form.Control type="number" placeholder="수량" onChange={e => {
                                let copy = [...option];
                                copy[i].quantity = e.target.value;
                                setOption(copy);
                            }} />
                        </Form.Group>
                    </Row>
                );
            })}
            <div className='text-center'>
                <Button className='w-25' variant="primary" onClick={submitHandler} >
                    등록하기
                </Button>
            </div>
        </Form>
    );
};
