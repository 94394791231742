import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import '../assets/css/OrderDetail.css'

const OrderDetail = () => {
    //wholesaler_id 받기
    const { orderCode } = useParams();
    const [order, setOrder] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/store/orders/${orderCode}`, { withCredentials: true })
            .then(response => {
                setOrder(response.data.result);
            })
    }, [])

    return (
        <>
            <Navbar />
            <Container>
                <h1 className='text-center mt-4'>주문 상세 정보</h1>
                {
                    order && <OrderDetailForm order={order} />
                }
            </Container>
        </>
    );
}

const OrderDetailForm = (props) => {
    const navigate = useNavigate();
    const { orderCode } = useParams();

    function handleProductClick(product) {
        console.log(product);
        navigate(`/products/list/${product.productId}`);
    }

    function handleOrderCancel(product) {
        navigate(`/products/list/${product.productId}`);
    }
    return (
        <div>
            <Form className='pb-4 w-75 mx-auto'>
                <Form.Group as={Col} controlId="name" className='mt-4'>
                    <Form.Label>주문 날짜/시간</Form.Label>
                    <Form.Control type="text" value={props.order.createdAt} disabled />
                </Form.Group>

                <Form.Group as={Col} controlId="phone" className='mt-4'>
                    <Form.Label>정가 총액</Form.Label>
                    <Form.Control type="text" value={props.order.subTotalPrice} disabled />
                </Form.Group>

                <Form.Group as={Col} controlId="address" className='mt-4'>
                    <Form.Label>할인 총액</Form.Label>
                    <Form.Control type="text" value={props.order.totalDiscountPrice} disabled />
                </Form.Group>

                <Form.Group as={Col} controlId="productNum" className='mt-4'>
                    <Form.Label>판매 금액</Form.Label>
                    <Form.Control type="number" value={props.order.totalPrice} disabled />
                </Form.Group>
                <div className='text-end pt-2'>
                    <Button variant='outline-secondary' onClick={() => { navigate(`/customer/receipts/${orderCode}`) }}> 영수증 </Button>
                </div>
            </Form>

            <div>
                <h4>상품목록</h4>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ fontWeight: 400 }}>#</th>
                        <th style={{ fontWeight: 400 }}>판매명</th>
                        <th style={{ fontWeight: 400 }}>색상</th>
                        <th style={{ fontWeight: 400 }}>사이즈</th>
                        <th style={{ fontWeight: 400 }}>수량</th>
                        <th style={{ fontWeight: 400 }}>정가</th>
                        <th style={{ fontWeight: 400 }}>판매가</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.order.orderStockList && props.order.orderStockList.map((os, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{os.name}</td>
                                    <td>{os.color}</td>
                                    <td>{os.size}</td>
                                    <td>{os.quantity}</td>
                                    <td>{os.tagPrice}</td>
                                    <td>{os.unitPrice}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
            {/* <div className='text-center'>
                <Button variant='danger' onClick={() => { handleOrderCancel() }}> 주문 취소 </Button>
            </div> */}
        </div>
    );
}

export default OrderDetail;

