import axios from 'axios';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useEffect, useState } from "react";
import { Container, Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";

const ProductDetailCustomer = () => {
    //product_id 받기
    const { productId, stockId } = useParams();
    const [product, setProduct] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/customers/${productId}/${stockId}`, { withCredentials: true })
            .then(response => {
                setProduct(response.data.result);
            })
    },[])

    return (
        <>
            <Container>
                <h1 className='text-center py-3 mt-3'>Product Info.</h1>
                {
                    product && <ProductDetailForm product={product} />

                }
            </Container>
        </>
    );
}

const ProductDetailForm = (props) => {
    const FormLabelEssential = styled.span`
    box-shadow: inset 0 -30px 0 #faf4a2;
    font-weight : bold;
`;

    const getSizeString = (array) => {
        const sizeArray = array.map(item => item.size); // size 값만 추출하여 배열 생성
        return sizeArray.join(' / '); // 배열 요소를 공백과 함께 결합하여 문자열 생성        
    }
    const getColorString = (array) => {
        const colorArray = array.map(item => item.color); 
        return colorArray.join(' / '); // 배열 요소를 공백과 함께 결합하여 문자열 생성        
    }
    return (
        <Form className='pb-5'>
            <Form.Group as={Col} className='py-1'>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" value={props.product.saleName} disabled />
            </Form.Group>

            <Form.Group as={Col} className='py-1'>
                <Form.Label>Size</Form.Label>
                <Form.Control type="text" value={getSizeString(props.product.sizeList)} disabled />
            </Form.Group>

            <Form.Group as={Col} className='py-1'>
                <Form.Label>Color</Form.Label>
                <Form.Control type="text" value={getColorString(props.product.colorList)} disabled />
            </Form.Group>
            {
                (props.product.discount.type != 'NONE')
                ? 
                <><Form.Group as={Col} className='py-1'>
                <Form.Label>Tag Price</Form.Label>
                <Form.Control type="text" value={`￥${props.product.tagPrice}`} disabled />
            </Form.Group>

            <Row className='py-1'>
                <Form.Group as={Col}>
                    <Form.Label>Discount Type</Form.Label>
                    <Form.Select value={props.product.discount.type} disabled>
                        <option selected value="NONE">無</option>
                        <option value="RATE">Rate</option>
                        <option value="FIX">Fix</option>
                    </Form.Select>
                </Form.Group>

                {(props.product.discount.type === 'FIX')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label>Discount Price</Form.Label>
                        <Form.Control type="text" value={`￥${props.product.discount.amount}`} disabled />

                    </Form.Group>
                    : null}
                {(props.product.discount.type === 'RATE')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label>Discount Rate</Form.Label>
                        <Form.Control type="text" value={`${props.product.discount.amount}%`} disabled />

                    </Form.Group>
                    : null}
            </Row>
            </>
                : null
            }
            

            <Form.Group as={Col} className='py-1'>
                <Form.Label><FormLabelEssential>Sale Price</FormLabelEssential></Form.Label>
                <Form.Control type="text" value={`￥${props.product.salePrice}`} disabled />
            </Form.Group>
        </Form>
    );
}

export default ProductDetailCustomer;

