// import axios from "../utils/Interceptors";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";

// let idx = 1;

const ProductDetail = () => {
    //product_id 받기
    const { id } = useParams();
    const [product, setProduct] = useState();


    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/store/products/list/${id}`, { withCredentials: true })
            .then(response => {
                setProduct(response.data.result);
            })
    }, [id])

    return (
        <>
            <Navbar />
            <Container>
                <h1 className='text-center py-3 mt-3'>상품 상세 정보</h1>
                {
                    product && <ProductDetailForm product={product} setProduct={setProduct}/>
                }
            </Container>
        </>
    );
}

const ProductDetailForm = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [modify, setModify] = useState(false);
    const [옵션수, 옵션수변경] = useState(props.product.stockList.length);
    const [카테고리선택, 카테고리선택변경] = useState(props.product ? props.product.category.parentId : 0);
    const [categories, setCategories] = useState([]);

    const [purchaseDate, setPurchaseDate] = useState(props.product.purchaseDate);
    const [purchaseStore, setPurchaseStore] = useState(props.product.wholesaler);
    const [purchaseName, setPurchaseName] = useState(props.product.purchaseName);
    const [saleName, setSaleName] = useState(props.product.saleName);
    const [category, setCategory] = useState(props.product.category.id);
    const [gender, setGender] = useState(props.product.gender);
    const [cost, setCost] = useState(props.product.cost);
    const [price, setPrice] = useState(props.product.tagPrice);
    const [discountType, setDiscountType] = useState(props.product.discount.type);
    const [discountAmount, setDiscountAmount] = useState(props.product.discount.amount);
    const [option, setOption] = useState();

    const FormLabelEssential = styled.span`
        box-shadow: inset 0 -30px 0 #faf4a2;
        font-weight : bolder;
    `;
    const FormLabel = styled.span`
        font-weight : bolder;
    `;

    //최초 한 번 서버에서 해당 상점의 카테고리 정보를 조회하여 상태 변수에 저장합니다.
    useEffect(() => {
        initCategories();
        initOption();
    }, []);

    // 해당 상점의 카테고리를 조회하는 함수
    function initCategories() {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/store/category`, { withCredentials: true })
            .then((response) => {
                const categoryList = response.data.result.categoryList;
                setCategories(categoryList);
            });
    }

    // 해당 상품의 옵션을 초기화하는 함수
    function initOption() {
        let optionList = [];
        props.product.stockList.map((stock, i) => {
            let newOption = {};

            newOption.id = stock.id;
            newOption.qrCode = stock.qrCode;
            newOption.color = stock.color;
            newOption.size = stock.size;
            newOption.quantity = stock.quantity;

            optionList.push(newOption);
        })
        setOption(optionList);
    }

    // 삭제 버튼을 눌렀을 때 호출되는 함수
    function deleteHandler() {
        const confirmDelete = window.confirm('ほんまに削除する？');
    
        if (confirmDelete) {
            const url = process.env.REACT_APP_HOST + `/api/v1/store/products/list/${id}`;
            const config = { headers: { 'Content-Type': 'application/json' }, withCredentials: true };
    
            axios.delete(url, config)
                .then(response => {
                    alert('상품 삭제 완료!');
                    navigate('/products/list');
                })
                .catch(error => {
                    console.error('상품 삭제 오류:', error);
                    alert('상품 삭제 중 오류가 발생했습니다.');
                });
        }
    }
    

    // 수정 후 저장 버튼을 눌렀을 때 호출되는 함수
    function submitHandler() {
        const url = process.env.REACT_APP_HOST + `/api/v1/store/products/list/${id}`;
        const data = {
            'purchaseDate': purchaseDate,
            'purchaseName': purchaseName,
            'wholesalerName': purchaseStore,
            'saleName': saleName,
            'category': category,
            'gender': gender,
            'tagPrice': price,
            'cost': cost,
            'discount': {
                'type': discountType,
                'amount': discountAmount
            },
            'stock': option
        };
        const config = { 'Content-Type': 'application/json', withCredentials: true };

        axios.put(url, data, config)
            .then(response => {
                if(response.data.resultCode === 'ERROR') {
                    alert("수정에 실패했습니다. 옵션 입력값을 확인하세요.")
                } else {
                    alert('수정에 성공했습니다.');
                    window.location.reload();
                }
            }).catch(err => {
                alert('수정에 실패했습니다.')
            });
    }

    // 수정 취소 버튼을 눌렀을 때 호출되는 함수
    function handleCancelEdit() {
        alert('변경 사항은 저장되지 않습니다.');
        window.location.reload();
    };

    return (
        <Form className='pb-5'>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseDate">
                    <Form.Label><FormLabel>사입 날짜</FormLabel></Form.Label>
                    <Form.Control type="date" onChange={e => setPurchaseDate(e.target.value)}
                        value={purchaseDate} disabled={!modify} />
                </Form.Group>

                <Form.Group as={Col} controlId="purchaseStore">
                    <Form.Label><FormLabel>거래처</FormLabel></Form.Label>
                    <Form.Control type="text" placeholder="거래처" onChange={e => setPurchaseStore(e.target.value)}
                        value={purchaseStore} disabled={!modify} />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>사입명</FormLabel></Form.Label>
                    <Form.Control type="text" placeholder='사입명' onChange={e => setPurchaseName(e.target.value)}
                        value={purchaseName} disabled={!modify} />
                </Form.Group>

                <Form.Group as={Col} controlId="saleName">
                    <Form.Label><FormLabelEssential>판매명 *</FormLabelEssential></Form.Label>
                    <Form.Control type="text" placeholder="판매명" onChange={e => setSaleName(e.target.value)}
                        value={saleName} disabled={!modify} />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabelEssential>1차 카테고리 *</FormLabelEssential></Form.Label>
                    <Form.Select onChange={e => { 카테고리선택변경(parseInt(e.target.value)) }} value={카테고리선택} disabled>
                        <option value={0}>1차 카테고리</option>
                        {categories.map(category => {
                            return (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabelEssential>2차 카테고리 *</FormLabelEssential></Form.Label>
                    <Form.Select onChange={e => setCategory(e.target.value)} value={category} disabled>
                        <option value={0}>2차 카테고리</option>
                        {categories && 카테고리선택 !== null
                            ? categories.filter(category => category.id === 카테고리선택)[0]?.children?.map(child => (
                                <option key={child.id} value={child.id}>{child.name}</option>
                            ))
                            : null
                        }
                    </Form.Select>
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label><FormLabel>성별</FormLabel></Form.Label>
                <Form.Select onChange={e => setGender(e.target.value)} value={gender} disabled={!modify}>
                    <option>성별</option>
                    <option value="WOMAN">여자</option>
                    <option value="MAN">남자</option>
                    <option value="BOTH">남여공용</option>
                </Form.Select>
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>사입 가격</FormLabel></Form.Label>
                    <Form.Control type="number" placeholder='사입 가격' onChange={e => setCost(e.target.value)} value={cost} disabled={!modify} />
                </Form.Group>

                <Form.Group as={Col} controlId="saleName">
                    <Form.Label><FormLabelEssential>판매 가격 *</FormLabelEssential></Form.Label>
                    <Form.Control type="number" placeholder="판매 가격" onChange={e => setPrice(e.target.value)} value={price} disabled={!modify} />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="purchaseName">
                    <Form.Label><FormLabel>할인 정책</FormLabel></Form.Label>
                    <Form.Select onChange={e => {setDiscountType(e.target.value); if(e.target.value === "NONE") setDiscountAmount(0)}} value={discountType} disabled={!modify}>
                        <option selected value="NONE">NONE</option>
                        <option value="RATE">RATE</option>
                        <option value="FIX">FIX</option>
                    </Form.Select>
                </Form.Group>

                {(discountType === 'FIX')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label><FormLabelEssential>할인 금액 *</FormLabelEssential></Form.Label>
                        <Form.Control type="number" placeholder="할인 금액" onChange={e => setDiscountAmount(e.target.value)} value={discountAmount} disabled={!modify} />
                    </Form.Group>
                    : null}
                {(discountType === 'RATE')
                    ? <Form.Group as={Col} controlId="saleName">
                        <Form.Label><FormLabelEssential>할인율 *</FormLabelEssential></Form.Label>
                        <Form.Control type="number" placeholder="할인율" onChange={e => setDiscountAmount(e.target.value)} value={discountAmount} disabled={!modify} />
                    </Form.Group>
                    : null}
            </Row>

            <div style={{ display: "flex", padding: "1rem" }}>
                <span><FormLabelEssential>상품/재고 옵션 *</FormLabelEssential></span>
                {
                    (modify)
                        ?
                        <>
                            <Button variant="outline-success" size='sm' style={{ marginLeft: "auto" }} onClick={() => {
                                let copy = [...option];
                                let newOption = { 'id': 0, 'qrCode': null, 'color': null, 'size': null, 'quantity': null };
                                copy.push(newOption);
                                setOption(copy);
                            }}>
                                옵션 추가
                            </Button>
                        </>
                        : null
                }
            </div>
            {option && option.map((opt, i) => {
                return (
                    <Row className="mb-1 align-items-center">
                        {
                            opt.qrCode === null
                                ? <h2 className='col text-center' controlId="qrCode">
                                    <Badge bg="warning">New</Badge>
                                </h2>

                                : <div className='col-2 text-center' controlId="qrCode">
                                    <img src={`data:image/png;base64,${opt.qrCode}`} alt={`QR Code ${i}`} style={{ width: "100%" }} />
                                </div>

                        }

                        <Form.Group as={Col} controlId={opt.id}>
                            <Form.Label><FormLabelEssential>색상</FormLabelEssential></Form.Label>
                            <Form.Control type="text" placeholder="색상" value={opt.color ?? ''} onChange={e => {
                                let copy = [...option];
                                const index = copy.findIndex(item => item.id === opt.id);
                                if (index !== -1) {
                                    copy[index] = {
                                        ...copy[index],
                                        color: e.target.value
                                    };
                                    setOption(copy);
                                }
                            }} disabled={!modify} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label><FormLabelEssential>사이즈</FormLabelEssential></Form.Label>
                            <Form.Control type="text" placeholder="사이즈" value={opt.size ?? ''} onChange={e => {
                                let copy = [...option];
                                const index = copy.findIndex(item => item.id === opt.id);
                                if (index !== -1) {
                                    copy[index] = {
                                        ...copy[index],
                                        size: e.target.value
                                    };
                                    setOption(copy);
                                }
                            }} disabled={!modify} />
                        </Form.Group>

                        <Form.Group as={Col} controlId={`${opt.id}_color`}>
                            <Form.Label><FormLabelEssential>수량</FormLabelEssential></Form.Label>
                            <Form.Control type="number" placeholder="수량" value={opt.quantity ?? '0'} onChange={e => {
                                let copy = [...option];
                                const index = copy.findIndex(item => item.id === opt.id);
                                if (index !== -1) {
                                    copy[index] = {
                                        ...copy[index],
                                        quantity: e.target.value
                                    };
                                    setOption(copy);
                                }
                            }} disabled={!modify} />
                        </Form.Group>
                    </Row>
                );
            })}

            {
                modify
                    ? <div className='text-center'>
                        <Button variant='danger' size='lg' className='m-4' onClick={() => { handleCancelEdit() }}>취소</Button>
                        <Button variant='success' size='lg' onClick={() => { submitHandler() }}>저장</Button>
                    </div>
                    : <div className='text-center'>
                        <Button variant="warning" size='lg' className='m-4'onClick={() => { setModify(true) }}>수정</Button>
                        <Button variant='danger' size='lg' onClick={() => { deleteHandler() }}>삭제</Button>
                    </div>
            }
        </Form>
    );
}

export default ProductDetail;

