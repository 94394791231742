import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';

import Navbar from '../components/Navbar';
import Scanner from '../components/Scanner';
import Bills from '../components/Bills';
import Calculator from '../components/Calculator';
import { useNavigate } from 'react-router-dom';
import NewScanner from '../components/NewScanner';

const Cart = () => {
    const [productId, setProductId] = useState(null);
    const [stockId, setStockId] = useState(null);
    const [cart, setCart] = useState([]);
    const [paid, setPaid] = useState(0);
    const [change, setChange] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (stockId !== null) {
            console.log(stockId);
            //stockId가 업데이트 되면 cart에서 존재하는지 찾아보기
            let copy = [...cart];
            const index = copy.findIndex(item => item.stockId + "" === stockId);
            if (index !== -1) {
                copy[index] = {
                    ...copy[index],
                    count: copy[index].count + 1
                };
                setCart(copy);
            } else {
                //stockId로 정보 가져오기
                axios.get(process.env.REACT_APP_HOST + `/api/v1/store/stocks/${stockId}`, { withCredentials: true })
                    .then((response) => {
                        let copy = [...cart];
                        response.data.result.count = 1;
                        copy.push(response.data.result);
                        setCart(copy);
                    })
            }

            setStockId(null);
        }
    }, [stockId])

    useEffect(() => {
        setChange(paid - totalPrice);
    }, [paid])

    useEffect(() => {
        console.log(cart);
    }, [cart])

    function sendOrder() {
        const url = process.env.REACT_APP_HOST + '/api/v1/store/orders'
        const data = {
            'totalPrice': totalPrice,
            'orderStocks': cart
        };
        const config = { 'Content-Type': 'application/json', withCredentials: true };

        axios.post(url, data, config)
            .then(response => {
                console.log(response);
                const orderCode = response.data.result.orderCode;
                navigate(`/customer/receipts/${orderCode}`);
            }).catch(err => {
                alert('주문 생성에 실패하였습니다.')
            })
    }

    return (
        <>
            <Navbar />
            <Container className='py-5'>
                <div className='mx-auto'>
                    <h1 className='text-center pb-3'>계산서</h1>
                    <Bills cart={cart} setCart={setCart} setChange={setChange} totalPrice={totalPrice} setTotalPrice={setTotalPrice} />
                </div>
                <Row>
                    <Col md={8}>
                        <NewScanner setProductId={setProductId} setStockId={setStockId} />
                    </Col>
                    <Col className='mx-auto py-5'>
                        <div className='text-center'>
                            <h4>계산기</h4>
                        </div>
                        <Calculator totalPrice={totalPrice} change={change} setPaid={setPaid} />
                        <div className='text-center'>
                            <Button variant='outline-success' onClick={() => { sendOrder() }}>계산완료</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Cart;
