import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

const CustomNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , removeCookie] = useCookies(['accessToken']);

  function logout() {
    removeCookie('accessToken');
    window.location.href = '/';
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand onClick={() => navigate('/')}>8784 Admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate('/products/scan')}>재고조회</Nav.Link>
            <Nav.Link onClick={() => navigate('/orders')}>계산</Nav.Link>
            <Nav.Link onClick={() => navigate('/wholesalers/list')}>거래처</Nav.Link>
            <NavDropdown title="상품관리" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate('/products/create')}>상품 등록</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/products/list')}>상품 목록</NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link onClick={() => navigate('/categories')}>카테고리</Nav.Link>
            <Nav.Link onClick={() => logout()}>로그아웃</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;