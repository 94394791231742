import Form from 'react-bootstrap/Form';

const Calculator = (props) => {
    return (
        <Form className='w-75 mx-auto'>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>결제 금액</Form.Label>
                <Form.Control type="text" value={props.totalPrice} disabled />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>받은 금액</Form.Label>
                <Form.Control type="number" placeholder="받은 금액" onChange={(e) => props.setPaid(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>거스름돈</Form.Label>
                <Form.Control type="number" placeholder="거스름돈" value={props.change} disabled />
            </Form.Group>
        </Form>
    );
}

export default Calculator;