import { Col, ListGroup, Button, Badge } from 'react-bootstrap';
import React from 'react';
import { useEffect, useState } from 'react';
import axios from "../utils/Interceptors";
import Navbar from '../components/Navbar.js'

const Categories = () => {

    //새로운 카테고리 추가시 이름에 필요한 인덱스를 저장하는 상태 변수
    const [idx, setIdx] = useState(1);
    //카테고리 상태를 저장하는 상태 변수
    const [categories, setCategories] = useState([]);
    //현재 선택된 아이템의 id를 저장하는 상태 변수
    const [selectedItemId, setSelectedItemId] = useState('top');

    //최초 한 번 서버에서 해당 상점의 카테고리 정보를 조회하여 상태 변수에 저장합니다.
    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/store/category`, { withCredentials: true })
            .then((response) => {
                let categoryList = response.data.result.categoryList;
                setCategories(categoryList);
            })
    }, [])

    // [추가] 버튼을 눌렀을 때 동작하는 메서드
    function addCategory() {
        // [Category Top]에서 [추가] 버튼을 눌렀을 때 부모 카테고리를 추가합니다.
        if (selectedItemId === 'top') {
            let copy = [...categories];
            copy.push({ id: 'new' + idx, depth: 1, childNum: 0, name: 'New Parent Category ' + idx, children: [{ id: 'newChild' + idx, depth: 2, parent: 'New Parent Category ' + idx, productNum: 0, name: 'New Child Category ' + idx }] });
            setCategories(copy);
            setIdx(idx + 1);
            // [부모 카테고리]에서 [추가] 버튼을 눌렀을 때 자식 카테고리를 추가합니다.
        } else if (categories.some((parent) => parent.id === selectedItemId)) {
            let copy = [...categories];
            copy.map((parent) => {
                if (parent.id === selectedItemId) {
                    parent.children.push({ id: 'new' + idx, depth: 2, parent: parent.name, productNum: 0, name: 'New Child Category ' + idx });
                    setCategories(copy);
                    setIdx(idx + 1);
                }
            })
            // [자식 카테고리]에서 [추가] 버튼을 눌렀을 때 오류 알림창을 띄웁니다.
        } else {
            alert('카테고리는 2차 분류까지 만드실 수 있습니다.');
        }
    }

    // 현재 선택된 아이템의 인덱스 찾기
    function findSelectedIndex() {
        let categoryIds = [];
        categories.map((parent) => {
            categoryIds.push(parent.id);
            parent.children.map((child) => {
                categoryIds.push(child.id);
            })
        })
        const selectedIndex = categoryIds.findIndex((id) => id === selectedItemId);
        return selectedIndex;
    }

    function modifyCategory() {
        const selectedIndex = findSelectedIndex();
        // 현재 선택된 아이템의 input 요소의 disabled 속성을 없애기
        let inputs = document.querySelectorAll('input');
        inputs[selectedIndex].disabled = false;
        inputs[selectedIndex].focus();
    }

    function changeName(event) {
        let copy = [...categories];
        copy.map((parent) => {
            if (parent.id + "" === event.target.id) {
                parent.name = event.target.value;
            } else {
                parent.children.map((child) => {
                    if (child.id + "" === event.target.id) {
                        child.name = event.target.value;
                    }
                })
            }
        })

        const selectedIndex = findSelectedIndex();
        let inputs = document.querySelectorAll('input');
        inputs[selectedIndex].disabled = true;
        setCategories(copy);
    }

    function deleteCategory() {
        let copy = [...categories];
        copy.map((parent) => {
            if (parent.id === selectedItemId) {
                if (copy.length === 1) {
                    alert('카테고리는 최소 1개 이상 존재해야합니다.')
                } else {
                    if (window.confirm('부모 카테고리를 삭제하는 경우 하위 모든 자식 카테고리까지 모두 삭제됩니다.')) {
                        let findIdx = copy.findIndex((parent) => parent.id === selectedItemId);
                        copy.splice(findIdx, 1);
                        alert('삭제하였습니다.');
                    } else {
                        alert('삭제를 취소했습니다.');
                    }
                }
            }

            parent.children.map((child) => {
                if (child.id === selectedItemId) {
                    if (parent.children.length === 1) {
                        alert('자식 카테고리가 최소 1개 존재해야합니다.')
                    } else {
                        if (window.confirm('카테고리에 속해 있는 ' + child.productNum + '개의 상품도 함께 삭제됩니다.')) {
                            let findIdx = parent.children.findIndex((child) => child.id === selectedItemId);
                            parent.children.splice(findIdx, 1);
                            alert('삭제하였습니다.');
                        } else {
                            alert('삭제를 취소했습니다.');
                        }
                    }
                }
            })
        })

        setCategories(copy);
    }

    function submitHandler() {
        let data = [];
        categories.map((parent) => {
            let parentElement = { id: parent.id, depth: 1, name: parent.name, children: [] }
            parent.children.map((child) => {
                let childElement = { id: child.id, depth: 2, name: child.name, children: [] }
                parentElement.children.push(childElement);
            })
            data.push(parentElement);
        })
        axios.put(process.env.REACT_APP_HOST + `/api/v1/store/category`, data, {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true
        })
            .then((response) => {
                alert('저장 성공');
                window.location.reload();
            })
    }

    return (
        <div>
            <Navbar />
            <div className='mx-auto pt-5 px-3'>
                <div className='text-center'>
                    <h1>카테고리 관리</h1>
                </div>
                <Col md={{ span: 6, offset: 3 }}>
                    <div className='list_btn pt-3'>
                        <Button size='sm' variant="outline-primary" onClick={addCategory}>추가</Button>{' '}
                        <Button size='sm' variant="outline-warning" onClick={modifyCategory}>수정</Button>{' '}
                        <Button size='sm' variant="outline-danger" onClick={deleteCategory}>삭제</Button>{' '}
                    </div>
                    <div className='pt-1'>
                        <ListGroup>
                            <ListGroup.Item key={'top'} action active={selectedItemId === 'top'} onClick={() => setSelectedItemId('top')}>Category Top</ListGroup.Item>
                            {
                                categories && categories.map((parent) => {
                                    return (
                                        <>
                                            <ListGroup.Item key={parent.id} action
                                                active={selectedItemId === parent.id}
                                                onClick={() => setSelectedItemId(parent.id)}>
                                                <input id={parent.id} defaultValue={parent.name} disabled onBlur={changeName}></input>
                                            </ListGroup.Item>

                                            {parent.children.map((child) => {
                                                return (
                                                    <ListGroup.Item key={child.id} action
                                                        active={selectedItemId === child.id}
                                                        onClick={() => setSelectedItemId(child.id)}>
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <input id={child.id} defaultValue={child.name} disabled onBlur={changeName}></input>
                                                        <Badge pill bg="dark">{child.productNum}</Badge>
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </>
                                    );
                                })
                            }
                        </ListGroup>
                    </div>
                    <div className='text-center pt-3 pb-5'>
                        <Button variant="outline-success" className='w-25' onClick={submitHandler}>저장</Button>{' '}
                    </div>
                </Col>
            </div>
        </div>
    );
}

export default Categories;