import React from 'react';

import Navbar from '../components/Navbar.js'
import { ProductForm } from '../components/ProductForm';
import { Col } from 'react-bootstrap';

const CreateProduct = () => {

    return (
        <div>
            <Navbar />
            <h1 style={{ textAlign: 'center', paddingTop: '2rem', fontWeight: 'bold' }}>상품 등록</h1>
            <div className='mx-auto py-5 px-3'>
                <Col md={{ span: 6, offset: 3 }}>
                    <ProductForm />
                </Col>
            </div>
        </div>
    );
}

export default CreateProduct;