import axios from 'axios';

axios.interceptors.response.use(
    success => success,
    async (error) => {
        const status = error.response.status

        if (status === 401) {
            // 현재 날짜 이전의 날짜를 생성하여 쿠키를 만료시킵니다.
            const pastDate = new Date(0);
            
            // 쿠키 만료일을 GMT 형식으로 설정합니다.
            const expires = "expires=" + pastDate.toUTCString();
            
            // document.cookie를 사용하여 쿠키를 삭제합니다.
            document.cookie = "accessToken" + "=; " + expires;

            window.location.href = '/';
            
            // const originRequest = error.config

            // await axios.post('/api/v1/auth/reissue', null, { withCredentials: true })
            //     .then(response => {
            //         return axios(originRequest);
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         window.location.href = '/';
            //     })
            return Promise.reject(error)
        }
        return Promise.reject(error);
    }
)
export default axios;