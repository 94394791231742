import Navbar from '../components/Navbar'
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import CustomPagination from '../components/CustomPagination';
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';

const Wholesaler = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [wholesalers, setWholesalers] = useState([]);
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1); // 페이지를 URL 파라미터에서 가져와 숫자로 변환합니다.


    useEffect(() => {
        setPage(searchParams.get('page'));
    }, [searchParams]);

    const targetUrl = useMemo(() => {
        const name = searchParams.get('name') || '';
        const address = searchParams.get('address') || '';
        let url = process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list?page=${page}`;
        if (name !== '') {
            url = process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list?name=${name}&page=${page}`;
        } else if (address !== '') {
            url = process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list?address=${address}&page=${page}`;
        }
        return url;
    }, [searchParams, page]);

    useEffect(() => {
        axios.get(targetUrl, { withCredentials: true })
            .then(response => {
                if (response.data.result) {
                    setTotalPages(response.data.result.totalPages);
                    setWholesalers(response.data.result.content);
                }
            });
    }, [targetUrl]);


    const [searchCondition, setSearchCondition] = useState('');
    const [searchText, setSearchText] = useState('');
    const navigateUrl = useMemo(() => `/wholesalers/list?${searchCondition}=${searchText}`, [searchCondition, searchText]);

    useEffect(() => {
        if (searchParams.get('purchaseName') !== null) {
            setSearchCondition('purchaseName')
            setSearchText(searchParams.get('purchaseName'))
        } else if (searchParams.get('saleName') !== null) {
            setSearchCondition('saleName')
            setSearchText(searchParams.get('saleName'))
        }
    }, [searchParams])

    function searchHandler() {
        if (searchCondition !== '') {
            let url = '';
            if (searchCondition === 'name') {
                url = process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list?name=${searchText}`;
            } else {
                url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?address=${searchText}`;
            }
            axios.get(url, { withCredentials: true })
                .then(response => {
                    navigate(navigateUrl);
                });
        } else {
            alert('검색조건을 선택해주세요.');
        }
    }

    return (
        <>
            <Navbar />
            <Container>
                <div className='m-4'>
                    <h2>거래처 목록</h2>
                </div>
                <>
                    <Form className='my-4'>
                        <Row>
                            <Form.Group as={Col} controlId="searchCondition">
                                <Form.Select onChange={e => { setSearchCondition(e.target.value) }}>
                                    <option value=''>검색</option>
                                    <option value='name'>거래처명</option>
                                    <option value='address'>주소</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='px-0' as={Col} xs={6} controlId="searchText">
                                <Form.Control type="text" onChange={e => { setSearchText(e.target.value) }}/>
                            </Form.Group>
                            <Col className='d-flex justify-content-center'>
                                <Button variant="success" onClick={() => { searchHandler() }}>
                                    검색
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
                <WholesalerTable wholesalers={wholesalers} />
                <CustomPagination navigateUrl={navigateUrl} searchParams={searchParams} totalPages={totalPages} />
            </Container>
        </>
    );
}

export default Wholesaler;

const WholesalerTable = (props) => {
    const navigate = useNavigate();

    function handleWholesalerClick(wholesaler) {
        navigate(`/wholesalers/list/${wholesaler.id}`);
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>상점명</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>상품수</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.wholesalers.map(wholesaler => {
                        return (
                            <tr key={wholesaler.id} onClick={() => handleWholesalerClick(wholesaler)} style={{ cursor: 'pointer' }}>
                                <td>{wholesaler.id}</td>
                                <td>{wholesaler.name}</td>
                                <td>{wholesaler.address}</td>
                                <td>{wholesaler.phone}</td>
                                <td>{wholesaler.productNum}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
}