import Navbar from '../components/Navbar'
import Table from 'react-bootstrap/Table';
import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import CustomPagination from '../components/CustomPagination';
import { Container, Row, Form, Col, Button } from 'react-bootstrap';

const ProductList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1); // 페이지를 URL 파라미터에서 가져와 숫자로 변환합니다.


    useEffect(() => {
        setPage(searchParams.get('page'));
    }, [searchParams]);

    const targetUrl = useMemo(() => {
        const purchaseName = searchParams.get('purchaseName') || '';
        const saleName = searchParams.get('saleName') || '';
        let url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?page=${page}`;
        if (purchaseName !== '') {
            url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?purchaseName=${purchaseName}&page=${page}`;
        } else if (saleName !== '') {
            url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?saleName=${saleName}&page=${page}`;
        }
        return url;
    }, [searchParams, page]);

    useEffect(() => {
        axios.get(targetUrl, { withCredentials: true })
            .then(response => {
                if (response.data.result) {
                    setTotalPages(response.data.result.totalPages);
                    setProducts(response.data.result.content);
                }
            });
    }, [targetUrl]);


    const [searchCondition, setSearchCondition] = useState('');
    const [searchText, setSearchText] = useState('');
    const navigateUrl = useMemo(() => `/products/list?${searchCondition}=${searchText}`, [searchCondition, searchText]);

    useEffect(() => {
        if (searchParams.get('purchaseName') !== null) {
            setSearchCondition('purchaseName')
            setSearchText(searchParams.get('purchaseName'))
        } else if (searchParams.get('saleName') !== null) {
            setSearchCondition('saleName')
            setSearchText(searchParams.get('saleName'))
        }
    }, [searchParams])

    function searchHandler() {
        if (searchCondition !== '') {
            let url = '';
            if (searchCondition === 'purchaseName') {
                url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?purchaseName=${searchText}`;
            } else {
                url = process.env.REACT_APP_HOST + `/api/v1/store/products/list?saleName=${searchText}`;
            }
            axios.get(url, { withCredentials: true })
                .then(response => {
                    navigate(navigateUrl);
                });
        } else {
            alert('검색조건을 선택해주세요.');
        }
    }

    function downloadHandler() {
        axios.get(process.env.REACT_APP_HOST + "/api/v1/store/products/download", { withCredentials: true, responseType: 'arraybuffer' })
            .then(response => {
                // Create a Blob from the response data
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(blob);
                // Create a temporary anchor element to trigger the download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'data.xlsx';
                a.click();
                // Clean up the temporary objects
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <>
            <Navbar />
            <Container>
                <Form className='my-4'>
                    <Row>
                        <Form.Group as={Col} controlId="searchCondition">
                            <Form.Select onChange={e => { setSearchCondition(e.target.value) }}>
                                <option value=''>검색</option>
                                <option value='purchaseName'>구매명</option>
                                <option value='saleName'>판매명</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='px-0' as={Col} xs={6} controlId="searchText">
                            <Form.Control type="text" onChange={e => { setSearchText(e.target.value) }} />
                        </Form.Group>
                        <Col className='d-flex justify-content-center'>
                            <Button variant="success" onClick={() => { searchHandler() }}>
                                검색
                            </Button>
                        </Col>
                        <Button className="mt-4" variant="warning" onClick={() => { downloadHandler() }}>
                                Download QR Images
                        </Button>
                    </Row>
                </Form>
                <div className=''>
                    <ProductTable products={products} />
                    <CustomPagination navigateUrl={navigateUrl} searchParams={searchParams} totalPages={totalPages} />
                </div>
            </Container>
        </>
    );
}

export default ProductList;

const ProductTable = (props) => {
    const navigate = useNavigate();

    function handleProductClick(product) {
        navigate(`/products/list/${product.id}`);
    }


    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    {/* <th>#</th> */}
                    {/* <th>사입 날짜</th> */}
                    {/* <th>구매명</th> */}
                    <th>판매명</th>
                    {/* <th>성별</th> */}
                    <th>원가</th>
                    <th>정가</th>
                    <th>판매가</th>
                    <th>거래처</th>
                    <th>사이즈</th>
                    <th>색상</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.products.map(product => {
                        return (
                            <tr key={product.id} onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }} >
                                {/* <td>{product.id}</td> */}
                                {/* <td>{product.purchaseDate}</td> */}
                                {/* <td>{product.purchaseName}</td> */}
                                <td>{product.saleName}</td>
                                {/* <td>{product.gender}</td> */}
                                <td>{product.cost}</td>
                                <td>{product.tagPrice}</td>
                                <td>{product.salePrice}</td>
                                <td>{product.purchaseStore}</td>
                                <td>{product.size.map(s => s).join('/')}</td>
                                <td>{product.color.map(s => s).join('/')}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
}