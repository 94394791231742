import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import '../assets/css/Receipt.css'
import Instagram from '../assets/img/instagram_logo_icon_170643.png';
import html2canvas from 'html2canvas';
import { useCookies } from 'react-cookie';

const Receipt = () => {
    const { orderCode } = useParams();
    const [order, setOrder] = useState();
    const [storeId, setStoreId] = useState();
    const [cookies] = useCookies(['accessToken']);


    useEffect(() => {
        // accessToken 가져오기
        const accessToken = cookies.accessToken;
        
        if (accessToken) {
            // 토큰 디코딩
            const tokenParts = accessToken.split('.');
            if (tokenParts.length === 3) {
                const payload = JSON.parse(atob(tokenParts[1]));
                const storeId = payload.storeId;
                setStoreId(storeId);
            }
        }

        axios.get(process.env.REACT_APP_HOST + `/api/v1/customers/orders/${orderCode}`, { withCredentials: true })
            .then(response => {
                setOrder(response.data.result);
            })
    }, [])


    function handleDownload() {
        // receipt-wrap 요소를 선택하여 캡쳐
        const receiptWrap = document.querySelector('.receipt-wrapper');
        html2canvas(receiptWrap).then((canvas) => {
            // canvas를 이미지로 변환
            const imgData = canvas.toDataURL('image/png');
            // 이미지 다운로드
            const a = document.createElement('a');
            a.href = imgData;
            a.download = '8784-receipt.png';
            a.click();
        });
    }
    return (
        <>
            {
                storeId !== null
                    ? <Navbar />
                    : null
            }
            <div className="parent-component">
                {
                    storeId !== null
                        ?
                        <div className='text-center pt-5'>
                            <h1 className='py-4'>구매해주셔서 감사드립니다!</h1>
                            <p>영수증이 필요하신 분은 <br /> 아래 QR코드를 찍어 다운로드 할 수 있습니다.</p>
                            <p>교환/환불은 택이 손상되지 않은채 <br /> 7일 이내 아래 영수증을 꼭 보여주세요.</p>
                            {order && <img src={`data:image/png;base64,${order.base64}`} />}
                        </div>
                        : null
                }
                <div className='d-flex justify-content-center align-items-center pt-5'>
                    <Button className='mx-2' variant="outline-secondary" onClick={handleDownload}>영수증 다운로드</Button>
                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={Instagram} /></a>
                </div>
                <div className="receipt-wrapper p-1">
                    <div className="receipt-wrap">
                        <h1>The Receipt</h1>
                        {/* <img src={`data:image/png;base64,`} /> */}
                        <header>
                            <dl class="info">
                                <div>
                                    <dt>Store</dt>
                                    <dd>8784</dd>
                                </div>
                                <div>
                                    <dt>Date</dt>
                                    <dd>{order && order.createdAt.split(' ')[0]}</dd>
                                </div>
                                <div>
                                    <dt>Code</dt>
                                    <dd>{orderCode}</dd>
                                </div>
                            </dl>
                        </header>
                        <main>
                            <table>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                </tr>
                                {
                                    order && order.orderStockList.map((orderStock, idx) => {
                                        return (
                                            <tr>
                                                <td>{`${orderStock.name}/${orderStock.color}/${orderStock.size}`}</td>
                                                <td>{orderStock.quantity} </td>
                                                <td><span class="price">{orderStock.tagPrice}</span></td>
                                            </tr>
                                        );
                                    })
                                }
                            </table>
                        </main>
                        <footer>
                            <dl class="info">
                                <div class="subtotal">
                                    <dt>Subtotal</dt>
                                    <dd><span class="price">{order && order.subTotalPrice}</span></dd>
                                </div>
                                <div class="discount">
                                    <dt>Discount</dt>
                                    <dd><span class="price">{order && order.totalDiscountPrice}</span></dd>
                                </div>
                                <div class="total">
                                    <dt>
                                        <h2>TOTAL</h2>
                                    </dt>
                                    <dd>
                                        <h2><span class="price">{order && order.totalPrice}</span></h2>
                                    </dd>
                                </div>
                            </dl>
                            <p class="greeting">Thank You For Shopping!</p>
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Receipt;