import '../assets/css/CalendarComponent.css'
import React, { useState, useRef, useEffect } from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// import bootstrapPlugin from '@fullcalendar/bootstrap' // a plugin!

const CalendarComponent = (props) => {
  const calendarRef = useRef(null); // FullCalendar Component를 담기 위한 Ref

  useEffect(() => {
    // FullCalendar Component가 마운트될 때 실행되는 부분
    const calendarApi = calendarRef.current.getApi(); // FullCalendar API
    props.setViewTitle(calendarApi.view.title); // View Title을 설정

    // datesSet 이벤트 핸들러 등록
    calendarApi.on('datesSet', (arg) => {
      props.setViewTitle(arg.view.title);
    });
  }, []);



  const handleDateClick = (arg) => { // bind with an arrow function
    props.setDate(arg.dateStr);
  }

  const handleDatesRender = (arg) => {
    console.log(arg.view.title);
  }

  return (
    <div className='calendarBox'>
      <FullCalendar
        // locale={'ja'}
        // aspectRatio={1.5} // FullCalendar의 너비와 높이 비율을 설정합니다.
        plugins={[dayGridPlugin, interactionPlugin]}
        selectable={true}
        dateClick={handleDateClick}
        events={props.events}
        ref={calendarRef} // FullCalendar Component를 담기 위한 Ref
        titleFormat={{
          year: 'numeric',
          month: '2-digit',
        }}
      />
    </div>
  );
};

export default CalendarComponent;
