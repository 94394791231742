// import axios from "../utils/Interceptors";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";

const WholesalerDetail = () => {
    //wholesaler_id 받기
    const { id } = useParams();
    const [wholesaler, setWholesaler] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list/${id}`, { withCredentials: true })
            .then(response => {
                setWholesaler(response.data.result);
            })
    }, [])

    return (
        <>
            <Navbar />
            <Container>
                <h1 className='text-center mt-5'>거래처 상세 정보</h1>
                {
                    wholesaler && <WholesalerDetailForm wholesaler={wholesaler} />
                }
            </Container>
        </>
    );
}

const WholesalerDetailForm = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [modify, setModify] = useState(false);

    const [name, setName] = useState(props.wholesaler.name);
    const [address, setAddress] = useState(props.wholesaler.address);
    const [phone, setPhone] = useState(props.wholesaler.phone);
    const [productNum, setProductNum] = useState(props.wholesaler.productNum);
    const [productList, setProductList] = useState(props.wholesaler.products);

    const FormLabelEssential = styled.span`
        box-shadow: inset 0 -30px 0 #faf4a2;
        font-weight : bolder;
    `;
    const FormLabel = styled.span`
        font-weight : bolder;
    `;

    function submitHandler() {
        const url = process.env.REACT_APP_HOST + `/api/v1/store/wholesalers/list/${id}`;
        const data = {
            'name': name,
            'address': address,
            'phone': phone
        };
        const config = { 'Content-Type': 'application/json', withCredentials: true };

        axios.put(url, data, config)
            .then(response => {
                setModify(false);
            }).catch(err => {
                console.log(err);
            });
    }

    function handleProductClick(product) {
        navigate(`/products/list/${product.id}`);
    }

    return (
        <div>
            <Form className='pb-5 w-75 mx-auto'>
                <Form.Group as={Col} controlId="name" className='mt-4'>
                    <Form.Label><FormLabelEssential>거래처 상점명</FormLabelEssential></Form.Label>
                    <Form.Control type="text" onChange={e => setName(e.target.value)} value={name} disabled />
                </Form.Group>

                <Form.Group as={Col} controlId="phone" className='mt-4'>
                    <Form.Label><FormLabel>연락처</FormLabel></Form.Label>
                    <Form.Control type="text" onChange={e => setPhone(e.target.value)} value={phone} disabled={!modify} />
                </Form.Group>

                <Form.Group as={Col} controlId="address" className='mt-4'>
                    <Form.Label><FormLabel>주소</FormLabel></Form.Label>
                    <Form.Control type="text" onChange={e => setAddress(e.target.value)} value={address} disabled={!modify} />
                </Form.Group>

                <Form.Group as={Col} controlId="productNum" className='mt-4'>
                    <Form.Label><FormLabel>상품수</FormLabel></Form.Label>
                    <Form.Control type="number" onChange={e => setProductNum(e.target.value)} value={productNum} disabled />
                </Form.Group>

                {
                    modify
                        ? <div className='text-center mt-3'>
                            <Button variant='danger' className='mx-3' onClick={() => { alert("변경 사항은 적용되지 않습니다."); window.location.reload() }}>
                                취소</Button>
                            <Button variant='success' onClick={() => { submitHandler() }}>
                                저장</Button>
                        </div>
                        : <div className='text-center mt-3'>
                            <Button variant="warning" onClick={() => { setModify(true) }}>
                                수정
                            </Button>
                        </div>
                }


            </Form>
            <div>
                <h4>거래목록</h4>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>사입 날짜</th>
                        <th>사입명</th>
                        <th>판매명</th>
                        <th>구매가</th>
                        <th>판매가</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        productList && productList.map(product => {
                            return (
                                <tr key={product.id} onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
                                    <td>{product.purchaseDate}</td>
                                    <td>{product.purchaseName}</td>
                                    <td>{product.saleName}</td>
                                    <td>{product.cost}</td>
                                    <td>{product.salePrice}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default WholesalerDetail;

